export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.containerScrolled': {
      backgroundColor: 'dark'
    },
    '.navItem': {
      color: 'light'
      // margin: 'auto'
    },
    '.logoLocationContainer': {
      padding: '0rem'
    },
    '.logo': {
      img: {
        maxHeight: '60px'
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: '50px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        maxHeight: '80px'
      }
    },
    '.phoneSocialContainer, .seperatorLine': {
      display: 'none'
    },
    '.hamburger, .hamburgerOpen': {
      borderColor: 'light',
      '> div': {
        backgroundColor: 'light'
      }
    },

    '.smallNavMenu': {
      display: ['none', '', '', '', 'flex'],
      '.order-online': {
        variant: 'buttons.primary',
        border: 'solid 2px',
        borderColor: 'primary'
      },
      '.reservations': {
        variant: 'buttons.primary',
        backgroundColor: 'transparent',
        color: '#e1d4cb',
        border: 'solid 2px #e1d4cb'
      },
      '.navItemDropdownMenu': {
        backgroundColor: 'dark',
        width: 'fit-content'
      },
      '.navItem': {
        m: 'auto 0.5rem'
      },
      '.navItemDropdown': {
        display: 'flex',
        '.navItem': {
          height: '100%',
          a: {
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }
        },
        '.navItemDropdownMenu': {
          bottom: '0px',
          transform: 'translateY(100%)'
        }
      }
    },
    '.navMenuOpen': {
      '.navItemDropdownMenu': {
        position: 'static',
        backgroundColor: 'dark'
      }
    }
  },

  footer: {
    borderTop: 'solid 2px',
    borderColor: 'primary',
    paddingBottom: ['7rem', '', '2rem'],
    '.gonationLogo': {
      width: 'auto'
    },
    '.logo': {
      filter: 'brightness(0) invert(1)'
    },
    '.multiButtonContainer': {
      a: {
        variant: 'buttons.secondary'
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginBottom: '1.5rem',
    color: 'white',
    order: '1'
  },
  subtitle: {
    color: 'secondary',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.1rem', '1.2rem', '1.5rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontFamily: 'subheading'
  },
  text: {
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit'
    }
  },

  borderCorners: {
    '::before': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1629742425/sites/cka-ka-qellu/Asset_1_2x.png)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '200px',
      height: '200px',
      position: 'absolute',
      left: '-1rem',
      top: '-1rem'
    }
    // '::after': {
    //   content: "''",
    //   backgroundImage:
    //     'url(https://res.cloudinary.com/gonation/image/upload/v1629742425/sites/cka-ka-qellu/Asset_1_2x.png)',
    //   backgroundSize: 'contain',
    //   backgroundRepeat: 'no-repeat',
    //   width: '200px',
    //   height: '200px',
    //   position: 'absolute',
    //   bottom: '-1rem',
    //   right: '-1rem',
    //   transform: 'rotate(180deg)'
    // }
  },

  sideBySide1: {
    padding: '1rem',
    minHeight: '60vh',
    '.lazyload-wrapper': {
      maxHeight: '75vh'
    },
    '.content': {
      variant: 'customVariants.borderCorners',
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '', '0rem 1rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  centerBlock: {
    variant: 'customVariants.sideBySide1',
    textAlign: 'center',
    '.section': {
      padding: '2rem',
      alignItems: 'center'
    }
  },

  pageHero: {
    variant: 'customVariants.centerBlock',
    minHeight: '100vh',
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1679331479/sites/Transilvania/castle.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundSize: '60%',
    backgroundColor: '#c4bcb4',
    '.section': {
      ml: '65%',
      width: '35%'
    },
    'h2.subtitle': {
      color: 'primary'
    },
    'div.text': {
      color: 'primary',
      '*': {
        color: 'primary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    marginBottom: '1.5rem',
    '.hero_content_container': {
      ml: ['', '', '', '4rem']
    },
    '.slick-slider': {
      img: {
        filter: 'brightness(0.7)'
      },
      '.slick-arrow': {
        display: 'none !important'
      }
    },
    '.slick-slider + div': { pointerEvents: 'none' },
    '.section': {
      margin: ['auto', '', '', 'auto auto 2rem 2rem'],
      padding: '1rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3.5rem', '4.5rem', '5.5rem'],
      border: 'none',
      margin: '0rem',
      padding: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    display: 'none !important'
  },

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      backgroundColor: 'secondary',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['black', '', '', '#5f4739']
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageOrder: {
    variant: 'customVariants.centerBlock',
    minHeight: '60vh',
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1679331479/sites/Transilvania/castle.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundSize: '60%',
    backgroundColor: '#c4bcb4',
    '.section': {
      ml: '65%',
      width: '35%'
    }
  },
  homepageMenu: {
    variant: 'customVariants.centerBlock'
  },
  homepagePrivateEvents: {
    variant: 'customVariants.sideBySide1'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },
  homepageCatering: {
    variant: 'customVariants.sideBySide1',
    overflow: 'hidden',
    'div.content': {
      height: 'fit-content',
      m: 'auto',
      zIndex: '2',
      position: 'relative',
      left: ['', '', '10vw'],
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1679324083/sites/Transilvania/slack-imgs.com.jpg)',
      '> *, div.text *': {
        color: 'dark'
      }
    }
  },

  homepageContactUs: {
    variant: 'customVariants.centerBlock',
    'h2.title': {
      textAlign: 'center'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    marginTop: '120px',
    '.albumTitle': {
      padding: '0.5rem'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        filter: 'brightness(0) invert(1)'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactIntro: {
    // variant: 'customVariants.pageHero'
    pt: '40vh',
    pb: '25vh',
    '.section': {
      backgroundColor: 'rgba(0,0,0,0.7)',
      padding: '2rem',
      margin: '0rem',
      maxWidth: '550px'
    }
  },

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: '#562316',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['2rem', '', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? =============================
  // ? ====  reservations page  ====
  // ? =============================

  reservations: {
    variant: 'customVariants.pageHero'
  },

  // ? =============================
  // ? ====  Catering page  ====
  // ? =============================

  catering: {
    variant: 'customVariants.pageHero'
  },

  // ? =============================
  // ? ====  private-events page  ====
  // ? =============================

  privateEvents: {
    variant: 'customVariants.pageHero'
  },

  // ? =====================
  // ? ====  Vlad page  ====
  // ? ========================

  vladHero: {
    variant: 'customVariants.pageHero',
    display: 'none'
  },

  vladPaper: {
    padding: ['1rem', '2rem', '3rem', '4rem'],
    margin: '2rem auto',
    maxWidth: '700px',
    padding: '1rem',
    border: '10px solid #392c2c',
    borderRadius: '255px 15px 225px / 15px 225px 15px 255px',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1679324083/sites/Transilvania/slack-imgs.com.jpg)',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    color: 'black',
    '.text': {
      '*': {
        color: 'black'
      }
    },
    '.title': { fontSize: '3.5rem' }
  },

  vlad1: {
    variant: 'customVariants.vladPaper'
  },
  vlad2: {
    variant: 'customVariants.vladPaper'
  },
  vlad3: {
    variant: 'customVariants.vladPaper'
  },
  vlad4: {
    variant: 'customVariants.vladPaper'
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.box': {
      padding: '1rem',
      border: '10px solid black',
      borderRadius: '255px 15px 225px / 15px 225px 15px 255px',
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1679324083/sites/Transilvania/slack-imgs.com.jpg)'
    },
    '.textContent': {
      '.subtitle': {
        fontSize: '1rem',
        fontFamily: 'body',
        color: 'dark'
      }
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      border: 'none',
      img: {
        objectFit: 'cover',
        padding: '0rem'
      }
    },
    '.boxesHeading': {
      display: 'none'
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
