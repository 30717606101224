export default {
  heading: 'Pirata One, serif',
  subheading: 'Cinzel, serif',
  body: 'Special Elite, serif',
  monospace: 'Menlo, monospace',
  display: 'Hahmlet, serif',
  googleFonts: [
    'Cinzel:100,200,300,400,500,600,700,800,900',
    'Hahmlet:100,200,300,400,500,600,700,800,900',
    'Special Elite',
    'Pirata One'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
