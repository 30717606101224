export default {
  primary: {
    backgroundColor: 'primary',
    color: 'text',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem 2rem 0.75rem',
    border: 'none',
    ':hover': {
      backgroundColor: 'dark'
    }
  },
  secondary: {
    color: 'text',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    fontFamily: 'body',
    border: 'none',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
